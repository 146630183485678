body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Microsoft JhengHei", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
    margin-bottom: 0;
}

a {
    text-decoration: none;
}

.position-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.pre {
    white-space: pre-wrap;
}

.r-0 {
    right: 0;
}

.page-content-link a,
.page-content-link span {
    text-decoration: none;
    color: $dark-blue;
    &:hover {
        color: $golden-brown;
    }
}

.bg-fixed-bottom {
    background: left bottom no-repeat;
    background-size: contain;
    background-attachment: fixed;
    background-repeat: repeat-x;
}

//分頁
.page-link {
    color: $dark-blue;
}

//input checked
.form-check-input:checked {
    background-color: $dark-blue;
    border-color: $dark-blue;
}

//手型符號
.cursor-pointer {
    cursor: pointer;
}

.white-space-pre--center {
    white-space: pre;
    text-align: center;
}

.arrowup-icon {
    &::after {
        font-family: "Font Awesome 5 Free";
        content: "\f077";
        color: #fff;
        font-weight: bolder;
        font-size: 20px;
    }
}
.arrowdown-icon {
    &::after {
        font-family: "Font Awesome 5 Free";
        content: "\f078";
        color: #fff;
        font-weight: bolder;
        font-size: 20px;
    }
}
.megaphone-icon {
    &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f0a1";
        font-weight: bolder;
        margin-right: 5px;
    }
}
.search-icon {
    &::after {
        font-family: "Font Awesome 5 Free";
        content: "\f002";
        color: #fff;
        font-weight: bolder;
        font-size: 20px;
    }
}
.times-icon {
    &::after {
        font-family: "Font Awesome 5 Free";
        content: "\f00d";
        color: #fff;
        font-weight: bolder;
        font-size: 20px;
    }
}
.dollar-icon {
    &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f155";
        font-weight: bolder;
        font-size: 18px;
        margin-right: 9px;
    }
}
.check-icon {
    &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f058";
        font-weight: bolder;
        font-size: 18px;
        margin-right: 5px;
    }
}
.paperclip-icon {
    &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f0c6";
        font-weight: bolder;
        font-size: 18px;
        margin-right: 5px;
    }
}
.file-icon {
    &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f0c5";
        font-weight: bolder;
        font-size: 18px;
        margin-right: 5px;
    }
}
.trash-icon {
    &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f1f8";
        font-weight: bolder;
    }
}
.download-icon {
    &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f019";
        font-weight: bolder;
    }
}

//slick
.slick-prev:before,
.slick-next:before {
    display: none;
    @include mw-992 {
        display: block;
        color: $golden-brown;
    }
}

//sweet alert
.swal2-styled.swal2-confirm {
    background-color: $golden-brown !important;
}

.swal2-icon.swal2-info,
.swal2-icon.swal2-error {
    border-color: $dark-blue !important;
    color: $dark-blue !important;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    background-color: $dark-blue !important;
}

.swal2-actions {
    button {
        outline: none !important;
    }
}

//bootstrap
.page-item.active .page-link {
    background-color: $dark-blue;
    border-color: $dark-blue;
}

.accordion-button::after {
    margin-right: 12px !important;
}

.accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: transparent !important;
    box-shadow: 0 !important;
}

.accordion-header {
    background-color: #f0f6ff;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background: $golden-brown;
    color: #fff;
}

.nav-link {
    color: #000;
}

//* scroll bar
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    transition: all 0.3s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.3s;
}