/*共用上方選單列*/
.top-nav {
    background: $golden-brown;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
    z-index: 300;
    // fixed 固定在上方
    // position: fixed;
    // top: 0;
    // left: 0;
    .form-control {
        color: $dark-blue;
    }
    a {
        color: #fff;
        text-decoration: none;
        display: block;
        border: 1px solid #fff;
        padding: 3px 0px;
        transition: all 0.3s;
        &:hover {
            background: $dark-blue;
            border: 1px solid $dark-blue !important;
        }
    }
    li {
        border-bottom: 2px solid transparent;
    }
}

.text-box {
    p {
        background: $dark-blue;
    }
}

.top_nav--active {
    background: $dark-blue;
    border: 1px solid $dark-blue !important;
}

/*增員素材 banner*/
.banner {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-bottom: 9px solid $golden-brown;
    height: 13rem;
    position: relative;
    @include mw-768 {
        height: 380px;
    }
    @include mw-992 {
        height: 450px;
    }
    &::before {
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.35);
        position: absolute;
        top: 0;
        left: 0;
    }
    .banner-title {
        font-size: 1.5rem;
        word-break: break-all;
        line-height: inherit;
        z-index: 100;
        @include mw-768 {
            font-size: 2rem;
        }
    }
}

/*inside 切換選單*/
.inside-nav {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    z-index: 300;
    a {
        color: black;
        padding: 16px 24px;
        border-bottom: 3px solid transparent;
        // &:hover{
        //     color: $golden-brown;
        //     border-bottom: 3px solid $golden-brown;
        // }
    }
}

.inside_nav--active {
    color: $golden-brown !important;
    border-bottom: 3px solid $golden-brown !important;
}

/*box*/
.insurance-box {
    max-width: 100%;
    padding: 12px;
    box-sizing: border-box;
    .box-header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        text-align: center;
        font-weight: bolder;
        padding: 10px 0;
        color: #fff;
        border: 1.5px solid;
        margin-bottom: -1.5px;
    }
    .box-body {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        text-align: center;
        border: 1.5px solid;
        padding: 10px;
        background: #fff;
        // height: 100%;
    }
    .box-num {
        font-size: 20px;
        font-weight: bolder;
        padding: 0 8px;
    }
}

/*主力商品 最下方 footer*/
.product-footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    .footer-item {
        &::after {
            content: "";
            margin: 0px 10px;
            background: #5e5e5e;
            border: 0.1px solid #5e5e5e;
            box-sizing: border-box;
            opacity: 0.4;
        }
    }
}

/*loading*/
.loading-wrapper {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
    }
    .spinner-border--width {
        width: 3rem;
        height: 3rem;
        border: 0.4em solid $golden-brown;
        border-right-color: transparent;
    }
}

.spinner-grow {
    width: 1.2rem;
    height: 1.2rem;
    &::before {
        content: "";
        width: 0.4rem;
        height: 0.4rem;
        background: rgba(255, 255, 255, 0.8);
        position: absolute;
        transform: rotate(45deg) translate(-50%, -50%);
        top: 57%;
        left: 33%;
        border-radius: 1.5px;
    }
}

//頁面跳轉動畫
.goto-page {
    position: relative;
    color: #fff;
    letter-spacing: 1px;
}
.goto-page::after {
    content: attr(data-text);
    position: absolute;
    top: 8px;
    left: 113px;
    width: 30%;
    color: #fff;
    overflow: hidden;
    animation: type 1s steps(11) infinite;
    -webkit-animation: type 1s steps(11) infinite;
    white-space: nowrap;
}
@keyframes type {
    0% {
        width: 0%;
    }
    100% {
        width: 20%;
    }
}

.dropdown-item {
    &.active {
        background: $dark-blue;
    }
    &:active {
        background: $dark-blue;
    }
}

.ellipsis {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: start;
    width: 235px;
}

//@ 流程步驟圖
.steps {
    position: relative;
    margin-bottom: 30px;
    counter-reset: step;
    display: flex;
    /*計數器容器*/
    & li {
        list-style-type: none;
        font-size: 14px;
        text-align: center;
        width: 25%;
        position: relative;
        float: left;
        color: #303335;
    }
    /*步驟數字*/
    & li:before {
        display: block;
        content: counter(step); /*設定計數器內容*/
        counter-increment: step; /*計數器值*/
        width: 36px;
        height: 36px;
        line-height: 34px;
        background-color: #315176;
        border-radius: 32px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        font-weight: 700;
        margin: 0 auto 8px auto;
        z-index: 2;
        position: relative;
        @media (max-width: 576px) {
            width: 38px;
            height: 38px;
            line-height: 38px;
        }
    }

    & li ~ li:after {
        content: "";
        width: 60%;
        height: 1.5px;
        position: absolute;
        left: -30%;
        top: 20px;
        transform: translateY(-50%);
        z-index: 1;
        //* 製作虛線
        background-image: linear-gradient(to right, #315176 0%, #315176 50%, transparent 50%);
        background-size: 6px 1px;
        background-repeat: repeat-x;
        @media (max-width: 576px) {
            width: 25%;
            left: -12%;
        }
    }

    & li.done {
        color: #666;
        &:before {
            content: url("../../images/icon/check.svg");
            background-color: rgba(#315176, 0.5);
            border: 1px solid #315176;
            line-height: 39px;
            @media (max-width: 576px) {
                line-height: 43px;
            }
        }
    }

    & li.active {
        color: #000;
    }

    & li.active ~ li:before {
        background-color: transparent;
        border: 1.5px solid #adb5bd;
        color: #adb5bd;
    }
    & li.active ~ li:after {
        height: 1.5px;
        //* 製作虛線
        background-image: linear-gradient(to right, #adb5bd 0%, #adb5bd 50%, transparent 50%);
    }
}
