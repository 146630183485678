.catbox {
    border: 1px solid $dark-blue;
    border-radius: 3px;
    padding: 15px;
    text-align: center;
    color: $dark-blue;
    &-active {
        background: $dark-blue;
        color: #fff;
    }
}

/*--保險公司 start--*/
.company-logo {
    height: 140px;
    object-fit: contain;
    @include mw-768 {
        height: 175px;
    }
    @include mw-992 {
        height: 125px;
    }
} //logo 統一固定高度 size
.company-header-height {
    height: 180px;
    @include mw-768 {
        height: 220px;
    }
    @include mw-992 {
        height: 188px;
    }
}
.company-paymentinfo-header-height {
    height: 230px;
    @include mw-768 {
        height: 250px;
    }
    @include mw-992 {
        height: 230px;
    }
}

.precautions-text {
    // 注意事項 icon
    &::before {
        content: "!";
        display: flex;
        justify-content: center;
        width: 30px;
        height: 30px;
        background: $golden-yellow;
        border-radius: 50px;
        border: 3px solid #000;
        margin-right: 8px;
    }
}
/*--保險公司 end--*/

/*--商品資訊 start--*/
.insured-amount--group {
    //財傳退休 保額 button
    button {
        font-size: 0.76rem;
    }
    input {
        border-right: transparent;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    span {
        color: #676767;
        font-size: 16px;
        display: flex;
        align-items: center;
        padding-right: 2px;
        border: 1px solid #ced4da;
        border-left: transparent;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }
}

.arrow-icon {
    position: absolute;
    top: 15px;
    right: 8px;
    color: #fff;
    font-size: 18px;
}

.animation-trans {
    transition: all 0.5s;
    animation: fadeIn 2s;
}

//商品佣金
.top-header {
    background: $golden-brown;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

//主力商品
.cal-icon {
    &::after {
        font-family: "Font Awesome 5 Free";
        content: "\f1ec";
        color: $dark-blue;
        font-weight: bolder;
        font-size: 28px;
    }
}

/*--商品資訊 end--*/

/*--保單專區 start--*/
.ecard-img img {
    max-width: 70%;
    height: auto;
}
.e-card {
    //個人資訊 -- 電子名片
    max-width: 100%;
    width: 350px;
    // height: 420px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.3);
    &::before {
        //棕色壓條
        content: "";
        display: block;
        width: 30%;
        height: 15px;
        background: $golden-brown;
        border-bottom-left-radius: 10px;
        position: absolute;
        left: 0;
        bottom: 0px;
    }
    &::after {
        // 黃色壓條
        content: "";
        width: 70%;
        height: 15px;
        display: block;
        background: $golden-yellow;
        border-bottom-right-radius: 10px;
        position: absolute;
        right: 0;
        bottom: 0px;
    }
    .info-header {
        line-height: 40px;
    }
    .info-list {
        //資訊列表
        line-height: 30px;
    }
    .special {
        li {
            &::before {
                content: "";
                margin-right: 4px;
                background: url("../../images/addMembers/list-style_2x.png") no-repeat center center;
                background-size: contain;
                width: 16px;
                height: 16px;
                display: inline-block;
            }
        }
    }
    .golden-company {
        //固定下方
        position: absolute;
        bottom: 24px;
        right: 16px;
        color: gray;
    }
}
/*--保單專區 end--*/

/*--增員素材 start--*/
.grow-item {
    // 使命優勢(公司成長歷程圖)
    @include mw-768 {
        position: relative;
    }
    .grow-line {
        background: url("../../images/addMembers/list-style_1x.png") no-repeat;
        width: 16px;
        height: 100%;
        position: relative;
        @include mw-768 {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            overflow: hidden;
        }
        span {
            margin-left: 50px;
        }
        &::after {
            content: "";
            width: 2px;
            height: 97%;
            background: $golden-yellow;
            position: absolute;
            top: 15px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.awards {
    //公勝優勢(獎項橫幅)
    // background: url('../../images/addMembers/goldenAdvantage/photo/secondBanner.png') no-repeat;
    background-color: #676767;
    background-size: contain;
    width: 100%;
    height: 180px;
}

//宣傳影片
.video {
    iframe {
        height: 300px;
        padding: 0 20px;
        @include mw-768 {
            height: 500px;
            padding: 0 50px;
        }
        @include mw-992 {
            height: 500px;
            padding: 0 50px;
        }
    }
}

/*--增員素材 end--*/

/*--現售商品 start--*/
.object-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.card-shadow {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.catOption {
    display: block;
    border: 1px solid $dark-blue;
    color: $dark-blue;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    .form-check-input {
        display: none !important;
    }
    &-active {
        background: $dark-blue;
        color: #fff;
    }
}

.page-active {
    background: $dark-blue;
    color: #fff;
}
.page-disabled {
    background: #dddddd;
    color: #b5b5b5;
}
/*--現售商品 end--*/

/*--保單查詢 start--*/
.query-title {
    width: 120px;
}
/*--保單查詢 end--*/

.cal {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }
}

// 公勝 30 五大挑戰
$challenges-yellow: #ffe707;
$challenges-blue: #00385d;
$challenges-lightblue: #c4d7e5;

@mixin typeIconBasic($bgColor, $bgUrl) {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $bgColor;
    position: relative;
    overflow: hidden;
    &::before {
        content: "";
        background-image: $bgUrl;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.challenge {
    table thead th {
        position: sticky;
        top: 0;
        z-index: 1;
    }
    &-header {
        text-align: center;
        background: $challenges-yellow;
        border-bottom-right-radius: 100px;
        padding: 15px 0;
        text-align: center;
        margin-bottom: 20px;
        h2 {
            margin-bottom: 8px;
            color: $challenges-blue;
        }
        p {
            font-size: 14px;
            color: #000;
        }
    }
    &-main-img {
        width: 30%;
        @include mw-768 {
            width: 20%;
        }
    }
    &-main-img_rank {
        width: 50%;
        @include mw-768 {
            width: 30%;
        }
    }
    &-main-img_award {
        width: 40%;
        @include mw-768 {
            width: 30%;
        }
        @include mw-992 {
            width: 20%;
        }
    }
    &-title {
        font-size: 32px;
        font-weight: bolder;
        margin-bottom: 12px;
        letter-spacing: 3px;
    }
    &-subtitle {
        line-height: 24px;
        letter-spacing: 3px;
    }
    &-progress {
        width: 100%;
        height: 20px;
        background-color: $challenges-lightblue;
        border: 4px solid #fff;
        border-radius: 30px;
    }
    &-progress-goal {
        width: 74px;
        height: 74px;
        border: 4px solid #fff;
        background-color: $challenges-lightblue;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: -20px;
        @include mw-992 {
            width: 100px;
            height: 100px;
        }
        .goal-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            white-space: nowrap;
            color: $challenges-blue;
            font-weight: bolder;
        }
    }
    &-progress-goal-success {
        background: linear-gradient(180deg, #ffe707 0%, #f5a100 100%) !important;
        &::before {
            content: "\f521";
            font-family: "Font Awesome 5 Free";
            font-weight: bolder;
            color: $challenges-lightblue;
            position: absolute;
            top: -23px;
            right: -5px;
            color: #fff;
            font-size: 25px;
            transform: rotate(30deg);
        }
    }
    &-social {
        .s-icon {
            @include typeIconBasic(#7dafde, url("../../images/challengesofGolden/icon/s.png"));
        }
        .j-icon {
            @include typeIconBasic(#e7a52f, url("../../images/challengesofGolden/icon/j.png"));
        }
        .h-icon {
            @include typeIconBasic(#d66179, url("../../images/challengesofGolden/icon/h.png"));
        }
        .c-icon {
            @include typeIconBasic(#009fa3, url("../../images/challengesofGolden/icon/c.png"));
        }
        .b-icon {
            @include typeIconBasic(#164477, url("../../images/challengesofGolden/icon/b.png"));
        }
        .strava-icon {
            width: 50px;
            height: 50px;
            background: #fc4c01;
            border-radius: 50%;
            position: relative;
            &::before {
                content: url("../../images/challengesofGolden/icon/strava.svg");
                width: 100%;
                height: 100%;
                position: absolute;
                top: 60%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    &-type-button > button {
        border-bottom: 2px solid transparent;
        padding-bottom: 4px;
    }
    &-award-img {
        width: 90%;
        @include mw-768 {
            width: 56%;
        }
        @include mw-992 {
            width: 75%;
        }
    }
    &-not {
        position: relative;
        &:hover {
            &::before {
                content: attr(title);
                color: $challenges-blue;
                font-weight: bolder;
                padding: 10px;
                margin: 0 5px;
                background: #fff;
                position: absolute;
                top: -50px;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 4px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
                white-space: nowrap;
            }
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 46%;
                transform: translateY(-50%);
                width: 10px;
                height: 10px;
                border-top: 8px solid #fff;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
                border-bottom: 8px solid transparent;
            }
        }
    }
}
.progress {
    border-radius: 30px;
    border: 4px solid #fff;
    background-color: #c4d7e5;
    height: 28px;
    width: 90%;
    @include mw-576 {
        width: 90%;
    }
    @include mw-768 {
        width: 95%;
    }
    &-bar {
        background: linear-gradient(180deg, #ffe707 0%, #f5a100 100%);
        border-radius: 30px;
        color: $challenges-blue;
        padding: 10px;
    }
}
.return-icon {
    //公勝 30 五大挑戰 - 返回首頁 button
    &::after {
        content: url("../../images/challengesofGolden/icon/return.svg");
        width: 100%;
        height: 100%;
        margin-left: 10px;
    }
    &:hover {
        background: transparent;
        border: 1px solid #fff;
        color: #fff;
    }
}
.card-yellow-box {
    &::after {
        content: "";
        width: 141px;
        height: 35px;
        background: #ffda2a;
        position: absolute;
        top: 133px;
        left: 28px;
    }
}

//--- iSmart + 滿意度問卷
// 字體 : Meng / NotoSan
.questionnaire {
    .title {
        letter-spacing: 2px;
        line-height: 45px;
        font-size: 28px;
        font-family: "Meng";
        color: #fff;
    }
    .number-box {
        padding: 5px 7px;
        color: #fff;
        font-weight: bolder;
        border-radius: 5px;
        width: 35px;
        margin-left: 8px;
    }
    .mes {
        position: relative;
        font-weight: bolder;
        &::after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 0 8px 8px;
            border-color: transparent transparent transparent #fec842;
            position: absolute;
            top: 50%;
            right: -6px;
            transform: translateY(-50%);
        }
    }
}

//--- conference 表揚大會(名字觸擊)
.conference {
    z-index: -2;
    position: absolute;
    opacity: 1;
    width: 100%;
    min-height: 100%;
    background-position:
        center center,
        center center;
    background-repeat: no-repeat;
    background-size: cover;
    .text {
        color: #fff;
        font-size: 33px;
        font-weight: bolder;
        text-shadow:
            0 0 1px red,
            0 0 50px red,
            0 0 100px red;
    }
}

//--- checkIn goldenfp (簽到_財顧)
.goldenfp-bg {
    background-image: url("../../images/goldenfp/財顧論壇簽到頁面.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 100vh;
}

//--- 信望愛簽到
.checkIn-btn {
    font-size: 18px;
    width: 150px;
    height: 50px;
    border-radius: 50px;
    background: $challenges-yellow;
    border: 2px solid #000;
    box-shadow: 5px 5px 0px -1px #000;
    &:active {
        box-shadow: none;
        transform: translate(10px, 10px);
    }
}
.checkIn-disabled-btn {
    font-size: 18px;
    width: 150px;
    height: 50px;
    border-radius: 50px;
    background: rgba($challenges-yellow, 0.7);
    border: 2px solid #000;
    box-shadow: 5px 5px 0px -1px #000;
    color: #3a3a3a;
}
.upload {
    //上傳按鈕
    &-img {
        width: 200px;
        // height: 155px;
        object-fit: cover;
        border: 2px solid #272727;
        box-shadow: 5px 5px 0px -1px #272727;
    }
    &-button {
        width: 125px;
        height: 40px;
        position: relative;
        border: 2.5px solid #272727;
        border-radius: 50px;
        &::before {
            content: "上傳圖片";
            font-weight: bolder;
            color: #fff;
            position: absolute;
            width: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }
    }
    &-button.file {
        &::before {
            content: "上傳檔案";
            font-weight: bolder;
        }
    }
    &-input {
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}
.check-state {
    font-size: 22px;
    color: #262626;
    border-radius: 50px;
    background: $warning;
    border: 3px solid #262626;
    display: inline-block;
    padding: 12px 20px;
}

.swal2-html-container {
    overflow: hidden !important;
}

//* react-pdf *//
.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
    display: none !important;
}
.react-pdf__Page {
    & > canvas {
        height: auto !important;
        max-width: 100%;
    }
}

//@ cardif-fireins
.cardif-fireins {
    .fee-box {
        position: sticky;
        bottom: 0;
        width: 100%;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
        border-top: 5px solid #00965e;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        z-index: 10;
    }
}
