@font-face {
    font-family: Meng;
    src: url("../../font/MENGNACHAOGANGHEI-1.OTF");
}

@font-face {
    font-family: NotoSan;
    src: url("../../font/NOTOSANSCJKTC-MEDIUM.OTF");
}

//color
$life-blue: #2c65c2;

canvas{
    width: 100%;
    height: 100%;
}

.my-image img{
    max-width: 100%;
    height: auto;
}

.lifebg-fixed-bottom {
    background: no-repeat;
    background-size: auto;
    background-position: 50% bottom;
}
.bg-wapper {
    p {
        margin: 0;
    }

    .pre {
        white-space: pre-line;
    }

    .form-control {
        padding: 0;
    }

    .title {
        //「樂退完美人生」
        letter-spacing: 3px;
        font-size: 42px;
        font-family: "Meng";
    }

    h3 {
        //列表標題文字
        font-size: 18px;
        font-weight: bolder;
        color: $life-blue;
    }

    .bg-wall {
        //白牆
        background-size: cover;
        background-repeat: no-repeat;
    }

    .life-line {
        //人生線
        .life-title {
            font-family: "Meng";
            font-size: 18px;
            width: 40px;
            margin: 0 auto;
            @include mw-576 {
                width: 100%;
                font-size: 20px;
            }
        }
        &::before {
            content: "";
            width: 90%;
            height: 1.5px;
            background: $life-blue;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        input {
            border: 0;
            background: #fff;
            text-align: center;
            padding: 0;
            font-weight: bolder;
            color: $life-blue;
        }
        span {
            white-space: nowrap;
        }
        //人生線的點
        .dot-box,
        .dot-box-first {
            &::before {
                content: "";
                width: 8px;
                height: 15px;
                background: $life-blue;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .dot-box-first {
            &::before {
                content: "";
                left: 30%;
            }
        }
    }

    .decoration-icon {
        //裝飾圖
        img:nth-child(odd) {
            //菱形
            position: absolute;
            right: -10px;
            bottom: 8px;
            width: 50px;
            @include mw-768 {
                width: 70px;
            }
        }
        img:nth-child(even) {
            //米型
            position: absolute;
            right: -15px;
            top: 280px;
            width: 50px;
            @include mw-768 {
                width: 70px;
                top: 200px;
            }
        }
    }

    .name,
    .date {
        border: 0;
        border-bottom: 1.5px solid $life-blue;
        width: 70%;
        color: $life-blue !important;
        font-weight: bolder;
    }

    .golden-num {
        font-family: "NotoSan";
        display: block;
        margin-top: 4px;
        font-size: 14px;
        @include mw-576 {
            font-family: "NotoSan";
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
}