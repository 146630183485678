// @new-----------
.dashboard {
    overflow: hidden;
    // @ Header
    .main-header {
        height: 65px;
    }
    //@ content scroll
    .content-scroll {
        overflow-y: scroll;
        height: 90vh;
    }

    .upload-btn-warp > input[type="file"] {
        display: none;
    }
}
// @-----------new

.dashboard-wrap {
    thead tr th {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 10;
    }
}

.dashboard_nav--active {
    background-color: $dark-blue;
    border-radius: 5px;
}

//發訊中心
.message-center {
    textarea,
    .box {
        height: 150px;
        border: 1px solid #cdcccc;
        background-color: #fff;
        overflow-y: scroll;
    }
}

//* 立即發訊 "預覽 modal"
.reviewmes {
    &-pic {
        background-image: url("../../images/golden_chicken.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 35px;
        height: 35px;
        margin-left: 10px;
        margin-bottom: 8px;
    }
    &-mes {
        background-color: #70e77c;
        display: inline-block;
        border-radius: 3px;
        padding: 5px;
        margin-left: 5px;
        position: relative;
        &::before {
            content: "";
            border-top: 8px solid transparent;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
            border-bottom: 8px solid #70e77c;
            position: absolute;
            top: -13px;
            left: 5px;
        }
    }
}

.scroll-box {
    overflow-y: scroll;
    height: 60vh;
    @include mw-768 {
        height: 65vh;
    }
    @include mw-992 {
        height: 65vh;
    }
}

.reverse-box {
    flex-direction: row-reverse;
    @include mw-992 {
        flex-direction: row;
    }
}

.earth-btn {
    &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f57e";
        font-size: 15px;
        font-weight: bolder;
        margin-right: 6px;
    }
}
.dept-btn {
    &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f1ad";
        font-size: 15px;
        font-weight: bolder;
        margin-right: 6px;
    }
}
.user-btn {
    &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f007";
        font-size: 15px;
        font-weight: bolder;
        margin-right: 6px;
    }
}
.table-btn {
    &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f0ce";
        font-size: 15px;
        font-weight: bolder;
        margin-right: 6px;
    }
}

.add-btn {
    &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f055";
        font-size: 20px;
        font-weight: bolder;
    }
}
.del-btn {
    &::after {
        font-family: "Font Awesome 5 Free";
        content: "\f2ed";
        font-size: 20px;
        font-weight: bolder;
    }
}
.role-btn {
    &::after {
        font-family: "Font Awesome 5 Free";
        content: "\f4fe";
        font-size: 20px;
        font-weight: bolder;
        color: #676767;
    }
}
.password-btn {
    &::after {
        font-family: "Font Awesome 5 Free";
        content: "\f084";
        font-size: 20px;
        font-weight: bolder;
        color: #676767;
    }
}
.edit-btn {
    &::after {
        font-family: "Font Awesome 5 Free";
        content: "\f044";
        font-size: 20px;
        font-weight: bolder;
        color: #676767;
    }
}

.string-len {
    font-size: 14px;
    font-weight: bolder;
    margin-left: 5px;
}

//推播統計
.push-item {
    margin: 10px 0;
    padding: 10px;
    color: #fff;
    border-radius: 8px;
    font-size: 18px;
}

//react-datepicker 日期選擇器

.react-datepicker__triangle {
    left: 50% !important;
    transform: translateX(-50%) !important;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
    // width: 45% !important;
    padding: 5px !important;
    border: 0 !important;
    margin: 5px 15px !important;
    font-size: 16px;
    font-weight: bolder;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
    background: #f4f4f4;
    border-radius: 5px;
}

.react-datepicker-popper {
    z-index: 49 !important;
}

//modal
// .modal-backdrop,
// .modal {
//     z-index: 10;
// }

.dot-line-bottom-light {
    //虛線
    padding: 10px 0;
    border: 1px dashed $light;
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
}

.btn {
    &-primary-gradient {
        background: linear-gradient(90deg, #315176 0%, #386496 100%);
        color: $light;
        transition: all 0.3s;
        box-shadow: 0px 0px 10px 0 rgba(#315176, 0.7);
        &:hover {
            background: linear-gradient(90deg, #315176 0%, #386496 100%);
            color: $light;
            box-shadow: 0px 0px 10px 0 rgba(#315176, 0.7);
        }
        &:active,
        &:focus {
            color: $light;
        }
    }
}

.dropdown-table.accordion {
    .accordion-item {
        border: 1px solid transparent;
        .table > :not(caption) > * > * {
            background-color: transparent;
        }
        table.trigger {
            tbody {
                tr:nth-child(4n + 1),
                tr:nth-child(4n + 2) {
                    background-color: transparent;
                    td {
                        background-color: rgba(0, 0, 0, 0.05);
                    }
                }
                tr:nth-child(4n + 3),
                tr:nth-child(4n + 4) {
                    background-color: white;
                    td {
                        background-color: transparent;
                    }
                }
                td {
                    vertical-align: middle;
                    .accordion-header {
                        background-color: transparent;
                        .accordion-button {
                            background-color: transparent;
                            width: fit-content;
                            padding: 0;
                            &:not(.collapsed) {
                                box-shadow: none;
                            }
                            &:focus {
                                box-shadow: none;
                            }
                            &:after {
                                margin-right: 0 !important;
                            }
                        }
                    }
                    table {
                        tbody {
                            // tr:nth-child(odd){
                            //     background-color: white;
                            // }
                            tr:nth-child(even) {
                                background-color: white;
                                td {
                                    background-color: white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
